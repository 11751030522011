<template>
    <b-container fluid>
        <div class="card">
            <div class="card-header">
                <div class="card-title">
                    Herkunftsländer
                </div>
                <div class="card-tools">
                    <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                        <ul class="navbar-nav">  
                        <li class="nav-item mr-1">
                            <div class="input-group mt-0">
                                <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="loadCountries" v-model="search"/>
                                <button type="submit" class="btn btn-default" @click.prevent="loadCountries">
                                    <i class="fas fa-search"></i>
                                </button>
                                <button v-if="search != null && search != ''" type="button" class="ml-1 btn btn-danger" @click="resetSearch">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </li>
                        <li>
                            <div class="input-group mt-0">
                            <button type="button" class="mr-1 btn btn-default" @click="loadCountries">
                                <i class="fas fa-sync"></i>
                            </button>
                            </div>
                        </li>
                        <li>
                            <div class="input-group mt-0">
                                <button class="btn btn-primary btn-md" v-if="$auth.check('countries.create')" @click="openStoreModal()">
                                    <i class="fas fa-plus"></i> Hinzufügen
                                </button>
                            </div>
                        </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="card-body">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>
                                <a href="#" @click.prevent="changeSort('country')">Herkunftsland</a>
                                <span v-if="this.params.sort_field == 'country' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                <span v-if="this.params.sort_field == 'country' && this.params.sort_direction == 'desc'" >&darr;</span>
                            </th>
                            <th>
                                <a href="#" @click.prevent="changeSort('iso2')">ISO2</a>
                                <span v-if="this.params.sort_field == 'iso2' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                <span v-if="this.params.sort_field == 'iso2' && this.params.sort_direction == 'desc'" >&darr;</span>
                            </th>
                            <th>
                                <a href="#" @click.prevent="changeSort('iso3')">ISO3</a>
                                <span v-if="this.params.sort_field == 'iso3' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                <span v-if="this.params.sort_field == 'iso3' && this.params.sort_direction == 'desc'" >&darr;</span>
                            </th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="country in countries.data" :key="country.id">
                            <td>{{ country.country }}</td>
                            <td>{{ country.iso2 }}</td>
                            <td>{{ country.iso3 }}</td>
                            <td>
                                <button class="btn btn-warning btn-xs mr-1"  v-if="$auth.check('countries.edit')" @click="openUpdateModal(country)">
                                    <i class="fas fa-edit"></i>
                                </button>
                                <button class="btn btn-danger btn-xs mr-1" v-if="$auth.check('countries.destroy')" @click="destroyCountry(country.id)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <pagination class="float-left" :data="countries" @pagination-change-page="loadCountries" :limit="3"></pagination>
                
                <select class="float-right form-control-sm" v-model="params.per_page">
                    <option value="25">25 Länder</option>
                    <option value="50">50 Länder</option>
                    <option value="75">75 Länder</option>
                    <option value="100">100 Länder</option>
                    <option value="125">125 Länder</option>
                    <option value="150">150 Länder</option>
                </select>
                <p v-if="notEmptyObject(countries)" class="float-right mr-2">Anzeige Eintrag {{ countries.meta.from }} - {{ countries.meta.to }} von {{ countries.meta.total }}</p>
            </div>
        </div>
        <b-modal id="countryModal" v-bind:title="'Herkungsländer erstellen'" ok-only ok-variant="danger" ok-title="Speichern" size="lg" @ok="storeCountry($event)">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Landesbezeichnung</label>
                        <input class="form-control form-control-sm" type="text" v-model="form.country" :class="{'is-invalid': form.errors.has('country')}">
                        <has-error :form="form" field="country"></has-error>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">ISO2</label>
                        <input class="form-control form-control-sm" type="text" v-model="form.iso2" :class="{'is-invalid': form.errors.has('iso2')}">
                        <has-error :form="form" field="iso2"></has-error>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">ISO3</label>
                        <input class="form-control form-control-sm" type="text" v-model="form.iso3" :class="{'is-invalid': form.errors.has('iso3')}">
                        <has-error :form="form" field="iso3"></has-error>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal id="countryEditModal" v-bind:title="'Herkungsländ bearbeiten'" ok-only ok-variant="danger" ok-title="Speichern" size="lg" @ok="updateCountry($event)">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Landesbezeichnung</label>
                        <input class="form-control form-control-sm" type="text" v-model="form.country" :class="{'is-invalid': form.errors.has('country')}">
                        <has-error :form="form" field="country"></has-error>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">ISO2</label>
                        <input class="form-control form-control-sm" type="text" v-model="form.iso2" :class="{'is-invalid': form.errors.has('iso2')}">
                        <has-error :form="form" field="iso2"></has-error>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">ISO3</label>
                        <input class="form-control form-control-sm" type="text" v-model="form.iso3" :class="{'is-invalid': form.errors.has('iso3')}">
                        <has-error :form="form" field="iso3"></has-error>
                    </div>
                </div>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
export default {
    name: 'Countries.index',
    title: 'Herkunftsländer',
    data() {
        return {
            countries: [],
            form: new window.Form({
                id: null,
                country: null,
                iso2: null,
                iso3: null
            }),
            search: null,
            page: 1,
            params: {
                per_page: 25,
                sort_direction: 'asc',
                sort_field: 'country',
            }
        }
    },
    watch: {
        'params': {
            handler() {
                this.loadCountries();
            },
            deep: true,
        }
    },
    methods: {
        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },
        resetSearch(){
            this.search = null;
            this.loadCountries();
        },
        openStoreModal() {
            this.form.clear();
            this.form.reset();
            this.$bvModal.show('countryModal')
        },
        openUpdateModal(country) {
            this.form.clear();
            this.form.reset();
            this.form.fill(country);
            this.$bvModal.show('countryEditModal');
        },
        loadCountries(page = undefined) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get('/countries', {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.countries = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                });
        },
        storeCountry(event) {
            event.preventDefault();
            this.form
                .post('/countries')
                .then(() => {
                    this.$bvModal.hide('countryModal');
                    this.loadCountries();
                })
                .catch(() => {
                });
        },
        updateCountry(event) {
            event.preventDefault();
            this.form
                .put('/countries/'+this.form.id)
                .then(() => {
                    this.$bvModal.hide('countryEditModal');
                    this.loadCountries();
                })
                .catch(() => {
                });
        },
        async destroyCountry(id) {
            await this.$swal({
                title: "Möchtest du das Land wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.axios
                        .delete('/countries/' + id)
                        .then(() => {
                            this.$bvModal.hide('countryModal');
                            this.loadCountries();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Da ist wohl etwas schief gelaufen'
                            })
                        })
                }
            });
        }
    },
    mounted() {
        this.loadCountries();
    }
}

</script>